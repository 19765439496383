import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Data from "../components/imagedata.json"
// {
    //     "data":[
    //         {"knitting":["kiitting/RFP (1).jpg","kiitting/RFP (2).jpg","kiitting/RFP (3).jpg","kiitting/RFP (4).jpg","kiitting/RFP (5).jpg","kiitting/RFP (6).jpg"]},
    //         {"tufting":["tufting/RFP (1).jpg","tufting/RFP (2).jpg","tufting/RFP (3).jpg","tufting/RFP (4).jpg","tufting/RFP (5).jpg","tufting/RFP (6).jpg","tufting/RFP (7).jpg","tufting/RFP (8).jpg","tufting/RFP (9).jpg","tufting/RFP (10).jpg","tufting/RFP (11).jpg","tufting/RFP (12).jpg","tufting/RFP (13).jpg","tufting/RFP (14).jpg","tufting/RFP (15).jpg"]},
    //         {"vandewielle":["vande/RFP (1).jpg","vande/RFP (2).jpg","vande/RFP (3).jpg","vande/RFP (4).jpg","vande/RFP (5).jpg","vande/RFP (6).jpg","vande/RFP (7).jpg","vande/RFP (8).jpg","vande/RFP (9).jpg","vande/RFP (10).jpg","vande/RFP (11).jpg","vande/RFP (12).jpg","vande/RFP (13).jpg","vande/RFP (14).jpg","vande/RFP (15).jpg","vande/RFP (16).jpg","vande/RFP (17).jpg"]}
    //     ]
    // }
    
class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productData: Object.keys(Data.data).reduce(function(res, v) {
                return res.concat(Data.data[v]);
            }, []),
            imagecount:6,
            allData: Data,
            category: "All Products"
          }
          window.scrollTo(0, 0); 
       
    }
    handleMoreImage = () => {
        console.log(this.state.imagecount);
        this.setState({imagecount: 6 + this.state.imagecount});
      };
  
    changeCategory=(value)=>{
        value==="all"? this.setState({productData:Object.keys(Data.data).reduce(function(res, v) {
            return res.concat(Data.data[v]);
        }, []),category:"All Products",imagecount: 6}):
        this.setState({productData:Data.data[Object.keys(Data.data).filter((data)=>data===value)[0]],category:value,imagecount: 6})
       
    }
    render() {
        return (
            <div>
                <div class="page_title">
                    <h1>Products</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                </div>
                <div class=" container-fluid  product_filter">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                <h3>{this.state.category}</h3>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="select_category">
                                    <label>Select Category</label>
                                    <select class="select_cat" onChange={(e)=>this.changeCategory(e.target.value)}>
                                        <option selected value="all">All Category</option>
                                        <option value="knitting">Knitting</option>
                                        <option value="tufting">Tufting</option>
                                        <option value="vandewielle">Van de wielle</option>
                                     </select>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div class=" container-fluid  product_listing pt-10 pb-10">
                    <div class="container">
                        <div class="row products">
                             {this.state.productData?.slice(0, this.state.imagecount).map((item,index)=>  (
                                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                                <Link to="/product-detail" class="product_item" key={index}>
                                    <div class="pr_thumbnail_img  "> <img src={`assets/img/${item}`} alt="" class="img-fluid" /></div>
                                    <div class="pr_actual_img hover-zoom"> <img src={`assets/img/${item}`}  alt="" class="img-fluid" /></div>
                                    <div class=" product_sku">{item.split("/")[1]}</div>
                                </Link>
                                </div>
                            ))}
                        </div>
                        {this.state.productData.length > this.state.imagecount &&
                        (
                        <div class="site_btn_groups justify-content-center">
                            <button class="site_btn btn_secondary " onClick={this.handleMoreImage}>Load More</button>
                        </div>
                        )}
                    </div>

                </div>
            </div>
        );
    }
}

export default Product;