import React, { Component } from 'react';
import { Link } from "react-router-dom";

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <div class="page_title">
                    <h1>About</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">About</li>
                        </ol>
                    </nav>

                </div>
                {/* <!--about_sec --> */}
                <div class=" container-fluid about_sec mt-10 mb-10" >
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12 img_col ">
                                <div class="row">
                                    <div class="col-6 mt-5">
                                        <div class="view bg-image hover-zoom mt-5">
                                            <img src="assets/img/about_img_1.png" width="306" height="500" alt="" class="img-fluid" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="view bg-image hover-zoom">
                                            <img src="assets/img/about_img_2.png" width="306" height="500" alt="" class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12 content_col" >
                                <h2 class="section_title"><span class="text-thin">A Trusted Textile Brand in</span>
                                    Yarn Carpet Manufacturer</h2>
                                <p>The pain itself is love, the main storage system. The full layer of soft chocolate varies. Maybe it was the weekend. Aeneas's pain becomes a part of his time. In this street was said to have dwelled.</p>

                                <ul class="about_listing">
                                    <li>Each makeup mass is not the main drink.</li>
                                    <li>It's just a mass of fermented sauce.</li>
                                    <li>The disease is always free in the bow of the ferry of life's makeup.</li>
                                </ul>

                                <div class="site_btn_groups">
                                    <Link to="/#" class="site_btn btn_secondary ">Read More</Link>
                                    <Link to="contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class=" container-fluid achievements_sec pt-10 pb-10" >
                    <div class="container ">
                        <h2 class="section_title text-center"><span class="text-thin">Our Best</span>
                            Achievements</h2>
                        <div class="row counter_row ">
                            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                                <div class="counter_col ">
                                    <div class="counter_icon">
                                        <i class="fas fa-user-alt"></i>
                                    </div>
                                    <div class="count">
                                        100
                                    </div>
                                    <div class="counter_text">
                                        Trusted Client
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                                <div class="counter_col ">
                                    <div class="counter_icon">
                                        <i class="fas fa-star"></i>
                                    </div>
                                    <div class="count">
                                        20
                                    </div>
                                    <div class="counter_text">
                                        Year of Experience
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                                <div class="counter_col ">
                                    <div class="counter_icon">
                                        <i class="fas fa-folder"></i>
                                    </div>
                                    <div class="count">
                                        300
                                    </div>
                                    <div class="counter_text">
                                        YFinished Project
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                                <div class="counter_col ">
                                    <div class="counter_icon">
                                        <i class="fas fa-industry"></i>
                                    </div>
                                    <div class="count">
                                        7
                                    </div>
                                    <div class="counter_text">
                                        Manufacturing Units
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* <!--workplace_sec--> */}

                <div class=" container-fluid workplace_sec mt-10 mb-10" >
                    <div class="container ">
                        <h2 class="section_title text-center"><span class="text-thin">Hitech & World Class</span>
                            Manufacturing Workplace</h2>
                        <div class="row ">
                            <div class="col-lg-6 col-md-6 img_col p-0">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid"/>
                            </div>
                            <div class="col-lg-6 col-md-6 content_col">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 img_col p-0 order-0 order-md-1">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid"/>
                            </div>
                            <div class="col-lg-6 col-md-6 content_col  ">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;