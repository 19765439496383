import React, { Component } from 'react';
import { Link } from "react-router-dom";

class workplace extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <div class="page_title">
                    <h1>Workplace</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Workplace</li>
                        </ol>
                    </nav>

                </div>

                {/* <!--workplace_sec--> */}

                <div class=" container-fluid workplace_sec mt-10 mb-10" >
                    <div class="container ">

                        <div class="row workplace_row">
                            <div class="col-lg-6 col-md-6 img_col p-0">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid" />
                            </div>
                            <div class="col-lg-6 col-md-6 content_col">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 img_col p-0">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid" />
                            </div>
                            <div class="col-lg-6 col-md-6 content_col  ">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>
                        </div>

                        <div class="row workplace_row">
                            <div class="col-lg-6 col-md-6 img_col p-0">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid" />
                            </div>
                            <div class="col-lg-6 col-md-6 content_col">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 img_col p-0 ">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid" />
                            </div>
                            <div class="col-lg-6 col-md-6 content_col  ">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default workplace;