import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <div>
                <footer class="pt-10 ">
                    <div class="container">
                        <div class="top_footer">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <Link class="footer_logo" to="/">
                                        <img src="assets/img/Sagar-Brothers-Logo-TM.png" width="416" height="60" alt="sagar brothers " class="img-fluid" loading="lazy" />
                                    </Link>
                                </div>
                                <div class="col-lg-8 col-md-12 col-sm-12">
                                    <div class="header_cta">
                                        <a href="https://goo.gl/maps/mSdCGdA6BoPeFCwe9" class="cta_item">
                                            <i class="far fa-address-book"></i>
                                            <div class="cts-detail">
                                                <span>Address</span>
                                                Surat, Gujarat, India
                                            </div>
                                        </a>
                                        <a href="tel:+91 99137 75397" class="cta_item">
                                            <i class="fas fa-phone-volume"></i>
                                            <div class="cts-detail">
                                                <span>Call Us Now!</span>
                                                +91 99137 75397
                                            </div>
                                        </a>
                                        <a href="mailto:sagar@sagarbrothers.in" class="cta_item">
                                            <i class="fas fa-envelope-open"></i>
                                            <div class="cts-detail">
                                                <span>Talk to us</span>
                                                sagar@sagarbrothers.in
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer_widget_row row mt-10 mb-10">
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="footer_widget widget_01">
                                    <h4 class="widget_title">About Sagar Brothers</h4>
                                    <a href="https://goo.gl/maps/mSdCGdA6BoPeFCwe9" class="cta_item">
                                    <p><i class="fas fa-home"></i>
                                        L/278-9-10  GIDC Pandesara 394221,
                                        Surat, Gujarat - India<br />
                                    </p>
                                    </a>
                                    <div class="social_media_links">
                                        <a href="https://www.facebook.com/" class="social_media_link">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a href="https://twitter.com/" class="social_media_link">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                        <a href="https://www.instagram.com/" class="social_media_link">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                        <a href="https://www.linkedin.com/" class="social_media_link">
                                            <i class="fab fa-linkedin"></i>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="footer_widget widget_02">
                                    <h4 class="widget_title">Customer Care</h4>
                                    <ul class="footer_nav_links" >
                                        <li><Link to="/terms&condition">Terms & Condition</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                        <li><Link to="/shipping">Shipping Details</Link></li>
                                        <li><Link to="/products">Online Order</Link></li>
                                        <li><Link to="/contact">Sample Request</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="footer_widget widget_02">
                                    <h4 class="widget_title">Navigation</h4>
                                    <ul class="footer_nav_links" >
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/workplace">Company</Link></li>
                                        <li><Link to="/products">Product</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div class="footer_widget widget_02">
                                    <h4 class="widget_title">Get Free Estimate</h4>
                                    <p>Our online scheduling and payment system is safe</p>
                                    <h3 class="contact_number"><a href="tel:+91 9913775397">+91 99137 75397</a></h3>
                                    <Link to="/about" class="site_btn btn_secondary ">Read More</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    <section class="copyright_footer">
                        <p>Copyright © 2019 Sagar Brothers</p>
                    </section>
                </footer>
            </div>
        );
    }
}

export default Footer;