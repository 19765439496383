import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Product from "./components/Product";
import Workplace from "./components/Workplace";
import ProductDetails from "./components/Product_Details";
import Contact from "./components/Contact";
import TermsCondition from './components/Terms_Condition';
import ShippingDetails from './components/Shipping_Details';

function App() {

  return (
    <Router >
    <div>
      <Header/>    
      <Switch>
        <Route exact={true} path={`${process.env.PUBLIC_URL}/`}><Home/></Route>
        <Route path={`${process.env.PUBLIC_URL}/about`}><About/></Route>
        <Route path={`${process.env.PUBLIC_URL}/products`}><Product /></Route>
        <Route path={`${process.env.PUBLIC_URL}/workplace`}><Workplace /></Route>
        <Route path={`${process.env.PUBLIC_URL}/contact`}><Contact /></Route>
        <Route path={`${process.env.PUBLIC_URL}/product-detail`}><ProductDetails /></Route>
        <Route path={`${process.env.PUBLIC_URL}/terms&condition`}><TermsCondition /></Route> 
        <Route path={`${process.env.PUBLIC_URL}/shipping`}><ShippingDetails /></Route> 
      </Switch>
      <Footer/>
    </div>
  </Router>
  
  );
}

export default App;
