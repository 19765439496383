import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert';

class Contact extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            message: '',
            contact_name: '',
            contact_email: '',
            contact_Mobile_No: '',
            contact_message: '',
            mail_message: '',
            show: false,
            contact_success: false,
            title: '',
            errors: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.handlecontactSubmit = this.handlecontactSubmit.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onContactConfirm = this.onContactConfirm.bind(this);

    }

    onConfirm() {
        this.setState({
            show: false
        });
    }

    onContactConfirm() {
        this.setState({
            contact_success: false
        });
    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleContactChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    handlecontactSubmit(event) {
        event.preventDefault();
        const isvalid = this.validateForm();
        if (isvalid) {
            const contact_name = this.state.contact_name;
            const contact_email = this.state.contact_email;
            const contact_Mobile_No = this.state.contact_Mobile_No;
            const contact_message = this.state.contact_message;
            const Company = this.state.Company;
            const Subject = this.state.Subject;

            let data = new FormData();
            data.append('name', contact_name);
            data.append('email', contact_email);
            data.append('Mobile_No', contact_Mobile_No);
            data.append('message', contact_message);
            data.append('Company', Company);
            data.append('Subject', Subject);

            const url = "/control/sagar_brother_email.php";
            axios.post(url, data).then(response => {
                console.log(response);
                this.setState({
                    mail_message: response.data,
                    contact_success: true
                });
            }).catch(error => {
                console.log(error);
            })
        }
    }


    validateForm() {

        let errors = {};
        let formIsValid = true;
        if (!this.state.contact_name) {
            formIsValid = false;
            errors["contact_name"] = "*Please enter your username.";
        }

        if (typeof this.state.contact_name !== "undefined") {
            if (!this.state.contact_name.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["contact_name"] = "*Please enter alphabet characters only.";
            }
        }

        if (!this.state.contact_email) {
            formIsValid = false;
            errors["contact_email"] = "*Please enter your email-ID.";
        }

        if (typeof this.state.contact_email !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.contact_email)) {
                formIsValid = false;
                errors["contact_email"] = "*Please enter valid email-ID.";
            }
        }

        if (!this.state.contact_Mobile_No) {
            formIsValid = false;
            errors["contact_Mobile_No"] = "*Please enter your mobile no.";
        }

        if (typeof this.state.contact_Mobile_No !== "undefined") {
            if (!this.state.contact_Mobile_No.match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["contact_Mobile_No"] = "*Please enter valid 10 digit mobile no.";
            }
        }

        if (!this.state.contact_message) {
            formIsValid = false;
            errors["contact_message"] = "*Please enter your message.";
        } 

        if (!this.state.Subject) {
            formIsValid = false;
            errors["Subject"] = "*Please enter your Subject.";
        }

        if (typeof this.state.Subject !== "undefined") {
            if (!this.state.Subject.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["Subject"] = "*Please enter alphabet characters only.";
            }
        }

        if (!this.state.Company) {
            formIsValid = false;
            errors["Company"] = "*Please enter your Company.";
        }

        if (typeof this.state.Company !== "undefined") {
            if (!this.state.Company.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["Company"] = "*Please enter alphabet characters only.";
            }
        }

        if (!this.state.terms) {
            formIsValid = false;
            errors["terms"] = "*Please mark the checkbox.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;


    }
    render() {
        return (
            <div>
                <div class="page_title">
                    <h1>Contact</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact</li>
                        </ol>
                    </nav>

                </div>

                {/* <!--workplace_sec--> */}

                <div class=" container-fluid  mt-10 mb-10" >
                    <div class="container ">
                        <h2 class="section_title text-center">Contact Form</h2>
                        <p class="text-center">Feel free to contact us through <a href="https://twitter.com/">Twitter</a> or <a href="https://www.facebook.com/">Facebook</a> if you prefer</p>
                        <form id="contact_form" onSubmit={this.handlecontactSubmit}>
                            <div class="row form_row">
                                <div class="col-md-4 col-sm-12 col-12">
                                    <input type="text" name="contact_name" placeholder="Your Name* " value={this.state.contact_name} onChange={this.handleContactChange} />
                                    <div className="errorMsg">{this.state.errors.contact_name}</div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-12">
                                    <input type="email" name="contact_email" placeholder="Your Email* " value={this.state.contact_email} onChange={this.handleContactChange}  />
                                    <div className="errorMsg">{this.state.errors.contact_email}</div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-12">
                                    <input type="number" name="contact_Mobile_No" placeholder="phone Number* " value={this.state.contact_Mobile_No} onChange={this.handleContactChange}  />
                                    <div className="errorMsg">{this.state.errors.contact_Mobile_No}</div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <input type="text" name="Company" placeholder="Company Name* " value={this.state.Company} onChange={this.handleContactChange}  />
                                    <div className="errorMsg">{this.state.errors.Company}</div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <input type="text" name="Subject" placeholder="Subject* " value={this.state.Subject} onChange={this.handleContactChange}  />
                                    <div className="errorMsg">{this.state.errors.Subject}</div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <textarea type="text" rows="6" name="contact_message" placeholder="Message* " value={this.state.contact_message} onChange={this.handleContactChange} ></textarea>
                                    <div className="errorMsg">{this.state.errors.contact_message}</div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <label id="terms"><input type="checkbox" for="terms" name="terms"  />I agree that my submitted data is being collected and stored.</label>
                                    <div id="terms" className="errorMsg text-danger">{this.state.errors.terms}</div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="site_btn_groups justify-content-center">
                                        <button type="submit" class="site_btn btn_secondary ">SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <SweetAlert success title="Good job!" show={this.state.contact_success} onConfirm={this.onContactConfirm}>
                            {this.state.mail_message}
                        </SweetAlert>
                    </div>
                </div>

                <div class=" container-fluid get_in_touch mt-10 mb-10" >
                    <div class="container ">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-12 " id="map">
                                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.1010375588576!2d72.83166901744384!3d21.14837699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be051d955560f0d%3A0xd15017e8e8ae9d4f!2s278%2C%20GIDC%20Pandesara%20Rd%2C%20Bhedwad%2C%20Laxmi%20Nagar%2C%20Pandesara%2C%20Udhna%2C%20Surat%2C%20Gujarat%20394220!5e0!3m2!1sen!2sin!4v1653373067271!5m2!1sen!2sin" width="100" height="100" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="get_in_touch_col">
                                    <h2>Get In Touch</h2>
                                    <p>Loream ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <ul>
                                        <li><a href="https://goo.gl/maps/mSdCGdA6BoPeFCwe9"><i class="far fa-address-book"></i><strong>Address:</strong> L/278-9-10  GIDC Pandesara 394221,
                                        Surat, Gujarat, India</a></li>
                                        <li><a href="tel:++91 99137 75397"><i class="fas fa-phone-volume"></i> <strong>Call Us Now:</strong> +91 99137 75397</a></li>
                                        <li><a href="mailto:sagar@sagarbrothers.in"><i class="fas fa-envelope-open"></i> <strong>Talk to us:</strong> sagar@sagarbrothers.in</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;