import React from 'react';
import { Link,  useLocation, withRouter } from "react-router-dom";

const Header = () => {
        //assigning location variable
        const location = useLocation();

        //destructuring pathname from location
        const { pathname } = location;
    
        //Javascript split method to get the name of the path in array
        const splitLocation = pathname.split("/");

    return (
            <div>
                <header>
                    {/* <!-- top header --> */}
                    {/* <section class="top_header"> */}
                    <div class="maintanance container-fluid">
                            <div class="row align-items-center">
                                <div class="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
                                <i class="fa fa-exclamation-triangle mantananceTitle" aria-hidden="true"></i><h5 className="mantananceTitle">Site Under Construction</h5>
                                </div>
                            </div>
                        </div>
                        {/* </section> */}
                    <section class="top_header">
                    





                        <div class="container custom_container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12 col-12 left_col">
                                    <ul class="">
                                        <li><a href="https://goo.gl/maps/mSdCGdA6BoPeFCwe9" class="header_link" rel="noreferrer" target="_blank"><i class="fas fa-map-marker-alt"></i>L/278-9-10 GIDC Pandesara 394221</a></li>
                                        <li><a href="mailto:sagar@sagarbrothers.in" class="header_link"><i class="fas fa-paper-plane"></i>sagar@sagarbrothers.in</a></li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12 col-12 right_col">
                                    <ul class="">
                                        {/* <li><a href="/#" class="header_link"><i class="far fa-calendar-alt"></i>Monday-Saturday: 09:00 - 18:00</a></li> */}
                                        <li class="follow_us">Follow Us
                                            <ul class="social_icon">
                                                <li><a href="https://www.facebook.com/" class="social_links"><i class="fab fa-facebook-square"></i></a></li>
                                                <li><a href="https://www.linkedin.com/" class="social_links"><i class="fab fa-linkedin"></i></a></li>
                                                <li><a href="https://twitter.com/" class="social_links"><i class="fab fa-twitter-square"></i></a></li>
                                                <li><a href="https://www.instagram.com/" class="social_links"><i class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!-- navbar -->
                    <!-- Navbar --> */}
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        {/* <!-- Container wrapper --> */}
                        <div class="container custom_container">

                            {/* <!-- Navbar brand --> */}
                            <Link class="navbar-brand mt-2 mt-lg-0" to={`${process.env.PUBLIC_URL}/`}>
                                <img src="assets/img/Sagar-Brothers-Logo-TM.png" width="416" height="60" alt="sagar brothers " loading="lazy" />
                            </Link>
                            {/* <!-- Toggle button --> */}
                            <button
                                class="navbar-toggler"
                                type="button"
                                data-mdb-toggle="collapse"
                                data-mdb-target="#mobile_nav_toggle"
                                aria-controls="mobile_nav_toggle"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i class="fas fa-bars"></i>
                            </button>
                            {/* <!-- Collapsible wrapper --> */}
                            <div class="collapse navbar-collapse" id="mobile_nav_toggle">

                                {/* <!-- Left links --> */}
                                <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                                    <li className={splitLocation[1] === "" ? "nav-item active" : "nav-item"} class="nav-item">
                                        <Link class="nav-link" to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                                    </li>
                                    <li className={splitLocation[1] === "about" ? "nav-item active" : "nav-item"} class="nav-item ">
                                        <Link class="nav-link" to={`${process.env.PUBLIC_URL}/about`}>About</Link>
                                    </li>
                                    <li className={splitLocation[1] === "products" ? "nav-item active" : "nav-item"} class="nav-item">
                                        <Link class="nav-link" to={`${process.env.PUBLIC_URL}/products`}>Products</Link>
                                    </li>
                                    <li className={splitLocation[1] === "workplace" ? "nav-item active" : "nav-item"} class="nav-item">
                                        <Link class="nav-link" to={`${process.env.PUBLIC_URL}/workplace`}>Workplace</Link>
                                    </li>
                                    <li className={splitLocation[1] === "contact" ? "nav-item active" : "nav-item"} class="nav-item">
                                        <Link class="nav-link" to={`${process.env.PUBLIC_URL}/contact`}>Contact</Link>
                                    </li>
                                </ul>
                                {/* <!-- Left links --> */}
                            </div>
                            {/* <!-- Collapsible wrapper --> */}

                            {/* <!-- Right elements --> */}
                            <div class="d-flex align-items-center contact_us_now">
                                <div class="header_cta">
                                    <a href="tel:+91 9913775397" class="cta_item">
                                        <i class="fas fa-phone-volume"></i>
                                        <div class="cts-detail">
                                            <span>Call Us Now!</span>
                                            +91 99137 75397
                                        </div>
                                    </a>
                                    <a href="mailto:sagar@sagarbrothers.in" class="cta_item">
                                        <i class="fas fa-envelope-open"></i>
                                        <div class="cts-detail">
                                            <span>Talk to us</span>
                                            sagar@sagarbrothers.in
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {/* <!-- Right elements --> */}
                        </div>
                        {/* <!-- Container wrapper --> */}
                    </nav>
                    {/* <!-- Navbar --> */}
                </header>
            </div>
        );
    
}

export default withRouter(Header);