import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Product_Details extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
        let custom = document.createElement('script')
        custom.setAttribute('src', '/assets/js/custom.js')
        document.head.appendChild(custom);
    }
    render() {
        return (
            <div>
                <div class="page_title">
                    <h1>Products detail</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb"/>
                    </nav>

                </div>

                <div class=" container-fluid  product_details pt-10 pb-10">
                    <div class="container">
                        <div class="row ">
                            <div class="col-md-5 col-sm-12 col-12">
                                <div class="product_image">
                                    <Link to="/#" class="product_item ">
                                        <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid"/></div>
                                        <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid"/></div>
                                    </Link>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-12 col-12">
                                <div class="product_summary">
                                    <h3 class="product_title">RFP-43</h3>
                                    <p class="text-primary"><strong>Consectetur adipiscing elit phasellus accumsan</strong></p>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan sem et purus laoreet elementum. Sed aliquam turpis nec feugiat cursus. Duis eget turpis in orci aliquet fringilla. Etiam quis dictum arcu. Nunc arcu velit, facilisis auctor posuere at, volutpat eget dui. Nunc scelerisque, purus in vestibulum bibendum, ante dolor blandit nisl, ac congue nunc nisl in nulla. Quisque id nisi vestibulum, euismod mauris in, sagittis neque. Fusce non pulvinar metus, nec vehicula magna. Duis id mattis leo. Proin vel hendrerit urna. Sed mattis maximus commodo. Phasellus arcu lectus, laoreet et finibus sed, convallis et lorem. Integer tincidunt egestas efficitur. In venenatis consectetur gravida. Nunc vulputate ante molestie dolor vehicula egestas. Donec sed tincidunt augue.
                                    </p>
                                    <Link to="/#" class="site_btn btn_secondary ">Order Now</Link>

                                    <div class=" product_gallery">
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/product_thmbnail80.png" alt="" title="" width="80" height="80" />
                                        </div>
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/banner_caption_img.png" alt="" title="" width="80" height="80" />
                                        </div>
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/product_thmbnail80.png" alt="" title="" width="80" height="80" />
                                        </div>
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/product_thmbnail80.png" alt="" title="" width="80" height="80" />
                                        </div>
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/product_thmbnail80.png" alt="" title="" width="80" height="80" />
                                        </div>
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/product_thmbnail80.png" alt="" title="" width="80" height="80" />
                                        </div>
                                        <div class="pr_gallery_item">
                                            <img src="assets/img/banner_caption_img.png" alt="" title="" width="80" height="80" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" container-fluid  product_content pb-10">
                    <div class="container">
                        <p class="text-primary"><strong>Consectetur adipiscing elit phasellus accumsan</strong></p>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan sem et purus laoreet elementum. Sed aliquam turpis nec feugiat cursus. Duis eget turpis in orci aliquet fringilla. Etiam quis dictum arcu. Nunc arcu velit, facilisis auctor posuere at, volutpat eget dui. Nunc scelerisque, purus in vestibulum bibendum, ante dolor blandit nisl, ac congue nunc nisl in nulla. Quisque id nisi vestibulum, euismod mauris in, sagittis neque. Fusce non pulvinar metus, nec vehicula magna. Duis id mattis leo. Proin vel hendrerit urna. Sed mattis maximus commodo. Phasellus arcu lectus, laoreet et finibus sed, convallis et lorem. Integer tincidunt egestas efficitur. In venenatis consectetur gravida. Nunc vulputate ante molestie dolor vehicula egestas. Donec sed tincidunt augue.
                        </p>
                        <p class="mt-5"><strong>Consectetur adipiscing elit phasellus accumsan</strong></p>
                        <ul class="list-style_caret md-count-2">
                            <li>Lorem ipsum dolor sit amet</li>
                            <li>Lorem ipsum dolor sit amet</li>
                            <li>Lorem ipsum dolor sit amet</li>
                            <li>Lorem ipsum dolor sit amet</li>
                            <li>Lorem ipsum dolor sit amet</li>
                            <li>Lorem ipsum dolor sit amet</li>
                            <li>Lorem ipsum dolor sit amet</li>
                        </ul>
                    </div>
                </div>




                <div class=" container-fluid  product_listing pt-10 pb-10">
                    <div class="container">
                        <h2 class="section_title text-center">
                            Related products</h2>
                        <div class="row products">
                            <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                                <Link to="/#" class="product_item ">
                                    <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid"/></div>
                                    <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid"/></div>
                                    <div class=" product_sku">RFP-43</div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                                <Link to="/#" class="product_item ">
                                    <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid"/></div>
                                    <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid"/></div>
                                    <div class=" product_sku">RFP-43</div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                                <Link to="/#" class="product_item ">
                                    <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid"/></div>
                                    <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid"/></div>
                                    <div class=" product_sku">RFP-43</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Product_Details;