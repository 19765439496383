import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";

function HomePrevArrow(props) {
    const { onClick } = props;
    return (
        <button class="carousel-control-prev" type="button" onClick={onClick} data-mdb-target="#Sagar-Brothers-banner" data-mdb-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
    );
}

function HomeNextArrow(props) {
    const { onClick } = props;
    return (
        <button class="carousel-control-next" type="button" onClick={onClick} data-mdb-target="#Sagar-Brothers-banner" data-mdb-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>

    );
}

function TestimonialsNextArrow(props) {
    const { onClick } = props;
    return (
        <button
            class="carousel-control-next"
            type="button"
            style={{ "margin-right": "6px" }}
            data-mdb-target="#Testimonial_slider"
            data-mdb-slide="next"
            onClick={onClick}
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    );
}

function TestimonialsPrevArrow(props) {
    const { onClick } = props;
    return (
        <button
            class="carousel-control-prev "
            type="button"
            style={{ "margin-left": "6px" }}
            data-mdb-target="#Testimonial_slider"
            data-mdb-slide="prev"
            onClick={onClick}
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>

    );
}


class home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        let custom = document.createElement('script')
        custom.setAttribute('src', '/assets/js/custom.js')
        document.head.appendChild(custom);
    }
    constructor(props) {
        super(props);
        this.next = this.ProductNextArrow.bind(this);
        this.previous = this.ProductPrevArrow.bind(this); 
      }
    ProductNextArrow() {
        this.slider.slickNext();
      }
    ProductPrevArrow() {
        this.slider.slickPrev();
      }
    render() {
        var home_slider = {
            dots: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            speed: 2000,
            variableWidth: true,
            nextArrow: <HomeNextArrow />,
            prevArrow: <HomePrevArrow />
        }

        var product_slider = {
            dots: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            speed: 1000,
            arrows:false,
            lazyLoad: true,
        };

        var Testimonials = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            speed: 2000,
            nextArrow: <TestimonialsNextArrow />,
            prevArrow: <TestimonialsPrevArrow />
        };
        return (
            <div>
                {/* <!-- Carousel wrapper --> */}
                <div id="Sagar-Brothers-banner" class="carousel slide carousel-fade" data-mdb-ride="carousel">

                    {/* <!-- Inner --> */}
                    <div class="carousel-inner">
                        <Slider {...home_slider} className="vission_slick">
                            {/* <!-- Single item --> */}
                            <div class="carousel-item active">
                                <img src="assets/img/banner_1.png" width="1920 " height="1082" alt="Sagar Btothers" />
                                <div class="container  carousel-caption d-none d-md-block">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="caption_content">
                                                <h4>Textile Brand Since 1970</h4>
                                                <h1>Carpet Yarn Manufecturer</h1>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit
                                                    dolor sit ameten on</p>
                                                <div class="site_btn_groups">
                                                    <Link to="about" class="site_btn btn_secondary ">Read More</Link>
                                                    <Link to="about" class="site_btn btn_primary">Discover More</Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <img src="assets/img/banner_caption_img.png" width="593" height="459" alt="Sagar Btothers" class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Single item --> */}
                            <div class="carousel-item active">
                                <img src="assets/img/banner_1.png" width="1920 " height="1082" alt="Sagar Btothers" />
                                <div class="container carousel-caption d-none d-md-block">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="caption_content">
                                                <h4>Textile Brand Since 1970</h4>
                                                <h1>Carpet Yarn Manufecturer</h1>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit
                                                    dolor sit ameten on</p>
                                                <div class="site_btn_groups">
                                                    <Link to="about" class="site_btn btn_secondary ">Read More</Link>
                                                    <Link to="about" class="site_btn btn_primary">Discover More</Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <img src="assets/img/banner_caption_img.png" width="593" height="459" alt="Sagar Btothers" class="img-fluid" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* <!-- Single item --> */}
                            <div class="carousel-item active">
                                <img src="assets/img/banner_1.png" width="1920 " height="1082" alt="Sagar Btothers" />
                                <div class=" container carousel-caption d-none d-md-block">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="caption_content">
                                                <h4>Textile Brand Since 1970</h4>
                                                <h1>Carpet Yarn Manufecturer</h1>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit
                                                    dolor sit ameten on</p>
                                                <div class="site_btn_groups">
                                                    <Link to="about" class="site_btn btn_secondary ">Read More</Link>
                                                    <Link to="about" class="site_btn btn_primary">Discover More</Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <img src="assets/img/banner_caption_img.png" width="593" height="459" alt="Sagar Btothers" class="img-fluid" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    {/* <!-- Inner --> */}


                </div>
                {/* <!-- Carousel wrapper --> */}

                {/* <!-- home_sec_2 --> */}
                <div class=" container-fluid home_sec_2">
                    <div class="container services_sec">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="services_col">
                                    <img src="assets/img/fabric.png" width="73" height="63" alt="fabric" title="fabric" />
                                    <h4>We Design Fabric</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur
                                        Proin tempus eros et scelerisque</p>
                                    <Link to="/about" class="read_more_link">Read More</Link>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="services_col">
                                    <img src="assets/img/manufacture.png" width="73" height="63" alt="fabric" title="fabric" />
                                    <h4>We Manufacture</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur
                                        Proin tempus eros et scelerisque</p>
                                    <Link to="/about" class="read_more_link">Read More</Link>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="services_col">
                                    <img src="assets/img/export.png" width="73" height="63" alt="fabric" title="fabric" />
                                    <h4>We Export Globally</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur
                                        Proin tempus eros et scelerisque</p>
                                    <Link to="/about" class="read_more_link">Read More</Link>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                {/* <!--about_sec --> */}
                <div class=" container-fluid about_sec mt-10 mb-10" >
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12 img_col ">
                                <div class="row">
                                    <div class="col-6 mt-5">
                                        <div class="view bg-image hover-zoom mt-5">
                                            <img src="assets/img/about_img_1.png" width="306" height="500" alt="" class="img-fluid" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="view bg-image hover-zoom">
                                            <img src="assets/img/about_img_2.png" width="306" height="500" alt="" class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12 content_col" >
                                <h2 class="section_title"><span class="text-thin">A Trusted Textile Brand in</span>
                                    Yarn Carpet Manufacturer</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius scelerisque tortor accumsan mollis. Aliquam erat volutpat. Aenean tempor dolor a fringilla efficitur. In hac habitasse platea dictumst.</p>

                                <ul class="about_listing">
                                    <li>Quisque rutrum massa non consectetur bibendum.</li>
                                    <li>Donec ultricies massa quis massa fermentum condimentum.</li>
                                    <li>Morbi semper libero in arcu porttitor, vitae rutrum nisi sodales.</li>
                                </ul>

                                <div class="site_btn_groups">
                                    <Link to="/about" class="site_btn btn_secondary ">Read More</Link>
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                {/* <!--best_product --> */}
                <div class=" container-fluid best_product pt-10 pb-10" >
                    <div class="container custom_container">
                        <div class="row align-items-center">

                            <div class="col-lg-4 col-md-5 col-12 content_col" >
                                <h2 class="section_title"><span class="text-thin">Our Best</span>
                                    Carpet Products</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius scelerisque tortor accumsan mollis. Aliquam erat volutpat. Aenean tempor dolor a fringilla efficitur. In hac habitasse platea dictumst.</p>

                                <div class="product_nav">
                                    <Link to="/products" class="site_btn btn_secondary ">View All Products</Link>
                                    <div class="product_nav_btn " onClick={this.previous}><i class="fas fa-angle-left"></i></div>
                                    <div class="product_nav_btn" onClick={this.next}><i class="fas fa-angle-right"></i></div>
                                </div>

                            </div>

                            <div class="col-lg-8 col-md-7 col-12 slider_col ">
                                <div class="product_slider_wrap">
                                    <div class="product_slider products">
                                        <Slider ref={c => (this.slider = c)} {...product_slider} >
                                            <Link to="/product-detail" class="product_item ">
                                                <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid" /></div>
                                                <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid" /></div>
                                                <div class=" product_sku">RFP-43</div>
                                            </Link>
                                            <Link to="/product-detail" class="product_item ">
                                                <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid" /></div>
                                                <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid" /></div>
                                                <div class=" product_sku">RFP-43</div>
                                            </Link>
                                            <Link to="/product-detail" class="product_item ">
                                                <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid" /></div>
                                                <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid" /></div>
                                                <div class=" product_sku">RFP-43</div>
                                            </Link>
                                            <Link to="/product-detail" class="product_item ">
                                                <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid" /></div>
                                                <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid" /></div>
                                                <div class=" product_sku">RFP-43</div>
                                            </Link>
                                            <Link to="/product-detail" class="product_item ">
                                                <div class="pr_thumbnail_img  "> <img src="assets/img/Layer_67.png" alt="" class="img-fluid" /></div>
                                                <div class="pr_actual_img hover-zoom"> <img src="assets/img/Layer_68.png" alt="" class="img-fluid" /></div>
                                                <div class=" product_sku">RFP-43</div>
                                            </Link>
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                {/* <!-- workplace_sec --> */}
                <div class=" container-fluid workplace_sec mt-10 mb-10" >
                    <div class="container ">
                        <h2 class="section_title text-center"><span class="text-thin">Hitech & World Class</span>
                            Manufacturing Workplace</h2>
                        <div class="row ">
                            <div class="col-lg-6 col-md-6 img_col p-0">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid" />
                            </div>
                            <div class="col-lg-6 col-md-6 content_col">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="/workplace" class="site_btn btn_secondary ">Read More</Link>
                                    <Link to="/contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 img_col p-0 order-0 order-md-1">
                                <img src="assets/img/Layer_70.png" width="648" height="432" alt="" class="img-fluid" />
                            </div>
                            <div class="col-lg-6 col-md-6 content_col  ">
                                <h3> A Trusted Textile Brand In Yarn Carpet Manufacturing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus eros et scelerisque tristique. Sed porta neque ac porta euismod. Sed elementum eget augue vel mattis. Fusce pellentesque neque ligula, vel sollicitudin lectus rutrum in.</p>
                                <div class="site_btn_groups justify-content-center">
                                    <Link to="workplasce" class="site_btn btn_secondary ">Read More</Link>
                                    <Link to="contact" class="site_btn btn_primary">Contact Us</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class=" container-fluid testimonial_sec pt-10 pb-10" >
                    <div class="container ">
                        <h2 class="section_title text-center"><span class="text-thin">Testimonials</span>
                            What Our Clients Say!</h2>

                        {/* <!-- Carousel wrapper --> */}
                        <div id="Testimonial_slider" class="carousel slide text-center carousel-dark"
                            data-mdb-ride="carousel">
                            {/* <div class="carousel-inner"> */}
                                <Slider {...Testimonials} className="vission_slick">
                                    <div class="carousel-item ">
                                        <div class="t-row">
                                            <i class="fas fa-quote-left "></i>
                                            <img

                                                class="rounded-circle shadow-1-strong mb-4"
                                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                                                alt="avatar"
                                                style={{ width: "150px", "margin-left": "42%" }}
                                            />

                                            <i class="fas fa-quote-right"></i>
                                        </div>

                                        <div class="row d-flex justify-content-center t_content">
                                            <div class="col-lg-8">
                                                <p class="text">
                                                    " Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                                                    nesciunt sint eligendi reprehenderit reiciendis, quibusdam illo, beatae quia
                                                    fugit consequatur laudantium velit magnam error. Consectetur distinctio fugit
                                                    doloremque."
                                                </p>
                                                <h4 class="mb-3">Maria Kate</h4>
                                                <p>Photographer Testimonials 1</p>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="carousel-item ">
                                        <div class="t-row">
                                            <i class="fas fa-quote-left"></i>
                                            <img
                                                class="rounded-circle shadow-1-strong mb-4"
                                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                                                alt="avatar"
                                                style={{ width: "150px", "margin-left": "42%" }}
                                            />
                                            <i class="fas fa-quote-right"></i>
                                        </div>

                                        <div class="row d-flex justify-content-center t_content">
                                            <div class="col-lg-8">
                                                <p class="text">
                                                    " Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                                                    nesciunt sint eligendi reprehenderit reiciendis, quibusdam illo, beatae quia
                                                    fugit consequatur laudantium velit magnam error. Consectetur distinctio fugit
                                                    doloremque."
                                                </p>
                                                <h4 class="mb-3">Maria Kate</h4>
                                                <p>Photographer Testimonials 2</p>
                                            </div>
                                        </div>

                                    </div>

                                </Slider>
                            {/* </div> */}

                        </div>
                        {/* <!-- Carousel wrapper --> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default home;