import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Shipping_Details extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
               <div class="page_title">
                    <h1>Shipping Details</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Shipping Details</li>
                        </ol>
                    </nav>

                </div> 

                <div class=" container-fluid  mt-10 mb-10" >
                    <div class="container ">
                        <h2 class="section_title text-center">Shipping Details</h2>
                        <p class="text-center">Shipping Details Coming Soon</p>
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Shipping_Details;